
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import DialogLayout from '@/components/DialogLayout.vue';
import {
  Orderline,
  OrderlineDetails,
  OrderlinePurchaseStateTypes,
  RushOption
} from '../store/models/OrderlineModel';
import { OrdersService } from '../services/orders-service';
import { ConversionHelper } from '../helpers/ConversionHelper';
import { AxiosResponse } from 'axios';
import { QuickDialog } from '../store/models/DialogModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { AmazonAccessService } from '@/services/amazon-access-service';
import { AmazonRequest } from '@/store/models/AmazonAccessModels';
import { Confirm } from '@/helpers/AuthHelper';
@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  $refs!: {};
  /* Properties */
  @Prop({ required: true })
  orderline!: Orderline;
  /* Store Actions */
  @Getter('getUserValidation', { namespace: 'profile' })
  userValidation!: any;
  /* Watchers */
  /* Data */
  amazonRequest: any = null;
  extendedPrice: number = 0;
  variableDataFee: number = 0;
  rushFee: number = 0;
  setUpFee: number = 0;
  extraRollFee: number = 0;
  dialog: boolean = false;
  dialogLayoutData: QuickDialog = new QuickDialog();
  /* Methods */
  async showDialog() {
    if (this.orderline) {
      this.dialogLayoutData = ConversionHelper.convertOrderlineToDialogLayout(
        this.orderline
      );
    }
    this.LoadAmazonRequest();
    await this.LoadPricingData();
    this.dialog = true;
  }
  closeDialog() {
    this.resetValues();
    this.dialog = false;
  }
  resetValues() {
    this.extendedPrice = 0;
    this.setUpFee = 0;
    this.variableDataFee = 0;
    this.rushFee = 0;
    this.extraRollFee = 0;
  }
  deleteOrderline() {
    if (this.amazonRequest && this.amazonRequest.IsComplete) {
      Confirm(
        () => {
          this.$emit('deleteOrderline', { AmazonRequest: this.amazonRequest });
          this.closeDialog();
        },
        'Codes already received!',
        'We have already received codes from Amazon. if you would like to delete this orderline, you will need to reach out to amazon transparency support to receive a refund for the requested codes'
      );
    } else {
      this.$emit('deleteOrderline', { AmazonRequest: this.amazonRequest });
      this.closeDialog();
    }
  }
  /* Loaders */
  async LoadPricingData() {
    let orderline = this.orderline;
    orderline.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Delete;
    const response = await OrdersService.CalculatePricingData([
      ConversionHelper.convertOrderlineToAddModel(orderline)
    ]);
    if (response.data[0]) {
      let ol = new OrderlineDetails(response.data[0]);
      this.extendedPrice = ol.Quantity * ol.PriceEach;
      this.setUpFee = ol.SetupCosts;
      this.variableDataFee = ol.Quantity * ol.VariableDataPriceEach;
      this.rushFee = ol.RushFee;
      this.extraRollFee = ol.HandApplyFee;
    } else {
      this.extendedPrice =
        this.orderline.Quantity * this.orderline.PriceEach + this.orderline.RushFee;
    }
  }
  async LoadAmazonRequest() {
    const { data } = await AmazonAccessService.GetAmazonRequest(this.orderline.Id);
    this.amazonRequest = new AmazonRequest(data);
  }
  /* Computed */
  get calculateSubTotal() {
    let result = this.extendedPrice + this.rushFee;
    return result.toFixed(2);
  }
  /* Mounted */
  mounted() {}
  /* Created */
}
