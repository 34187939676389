
import { ConstValues } from '@/ConstValues';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import ShippingService from '@/services/shipping-service';
import DialogLayout from './DialogLayout.vue';
import RushIcon from '@/components/Icons/RushIcon.vue';
import { QuickDialog } from '@/store/models/DialogModel';
import {
  OrderlineDetails,
  RushOption,
  ShippingDeliveryMethod
} from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import AdminService from '@/services/admin-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { ChangeOrderlineDueDateModel } from '@/store/models/AdminModel';
import { OrdersService } from '@/services/orders-service';
@Component({
  components: {
    DialogLayout,
    RushIcon
  }
})
export default class OrderEditRushAdmin extends Vue {
  /* Properties */
  @Prop({ required: true })
  orderline!: OrderlineDetails;
  @Prop()
  isInternational!: boolean;
  /* Store Actions */
  /* Watchers */
  @Watch('isARush')
  onIsARushChange(val: boolean) {
    if (!val) {
      this.rushOption = this.rushOptions.find(r => r.DayValue == 10);
      this.rushFee = this.rushOption.RushFee;
      return;
    }
    if (this.orderline.RushOption.PublicId) {
      this.setRushOptionFromOrderline();
    } else {
      this.shouldAutoSuggestDeliveryOptions = true;
      this.rushOption = this.rushOptions.find(r => r.DayValue == 5);
      this.rushFee = this.rushOption.RushFee;
    }
  }
  /* Data */
  deliveryOptions: ShippingDeliveryMethod[] = [];
  selectedDeliveryOption: ShippingDeliveryMethod = new ShippingDeliveryMethod();
  originalDeliveryOption: ShippingDeliveryMethod = new ShippingDeliveryMethod();
  dialogLayoutData: QuickDialog = new QuickDialog();
  hasChanged: boolean = false;
  isARush: boolean = false;
  isLoading: boolean = false;
  isChangingDate: boolean = false;
  dateMenu: boolean = false;
  dialog: boolean = false;
  picker: string = new Date(this.orderline.DueDate).toISOString().substr(0, 10);
  rushFee: number = this.orderline.RushFee;
  rushOptions: RushOption[] = [new RushOption()];
  rushOption: RushOption = new RushOption();
  isGettingRushFees: boolean = false;
  shouldApplyToEntireOrder: boolean = false;
  shouldAutoSuggestDeliveryOptions: boolean = true;
  shouldAddReason: boolean = false;
  rushFeeChangeReason: string = '';

  /* Computed */
  get ConstValues() {
    return ConstValues;
  }
  /* Async Functions */
  async changeOrderlineDueDate() {
    this.isChangingDate = true;
    try {
      const model: ChangeOrderlineDueDateModel = {
        OrderlineId: this.orderline.Id,
        Date: this.picker,
        IsARush: this.isARush,
        RushFee: this.rushFee,
        DeliveryOptionId: this.selectedDeliveryOption.Id,
        RushOptionId: this.rushOption.PublicId,
        ShouldApplyToWholeOrder: this.shouldApplyToEntireOrder
      };
      await AdminService.ChangeOrderlineDueDate(model);
      SetSnackBar('Changed date successfully');
      if (this.shouldApplyToEntireOrder) {
        this.$emit('refreshWholeOrder');
      } else {
        this.$emit('refreshOrderline');
      }
      this.closeDialog();
    } catch (error) {
      SetSnackBar('Failed to change due date');
    }
    this.isChangingDate = false;
  }
  /* Utility Functions */
  resetData() {
    this.rushOption = new RushOption();
    this.rushOptions = [];
    this.isARush = false;
    this.isLoading = false;
    this.isChangingDate = false;
    this.isGettingRushFees = false;
  }
  setRushOptionFromOrderline() {
    if (this.orderline.RushOption?.PublicId) {
      this.isARush = true;
      this.shouldAutoSuggestDeliveryOptions = false;
      this.rushOption = this.rushOptions.find(
        r => r.PublicId == this.orderline.RushOption.PublicId
      );
      this.rushFee = this.orderline.RushFee;
    }
  }
  async showDialog() {
    this.resetData();
    if (this.orderline) {
      this.dialogLayoutData = ConversionHelper.convertOrderlineToDialogLayout(
        this.orderline
      );
    }
    this.getDeliveryOptions();
    await this.LoadRushFeeValues();
    this.setRushOptionFromOrderline();
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  updateDeliveryOptionFromRushDate(rushOption: RushOption) {
    this.rushFee = rushOption.RushFee;
    if (!this.shouldAutoSuggestDeliveryOptions) return;
    if (this.isInternational) {
      switch (rushOption.DayValue) {
        case 1:
        case 2:
        case 3:
        case 4: {
          this.selectedDeliveryOption =
            this.deliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTRUSHFORINTERNATIONALOPTIONID
            ) ?? new ShippingDeliveryMethod();
          return;
        }
        default: {
          this.selectedDeliveryOption =
            this.deliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTFORINTERNATIONALOPTIONID
            ) ?? new ShippingDeliveryMethod();
          return;
        }
      }
    } else {
      switch (rushOption.DayValue) {
        case 1:
          this.selectedDeliveryOption =
            this.deliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTRUSHLEVEL1DELIVERYOPTIONID
            ) ?? new ShippingDeliveryMethod();
          return;
        case 2:
        case 3:
          this.selectedDeliveryOption =
            this.deliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTRUSHLEVEL2DELIVERYOPTIONID
            ) ?? new ShippingDeliveryMethod();
          return;
        default:
          this.selectedDeliveryOption = this.selectedDeliveryOption =
            this.deliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTRUSHLEVEL2DELIVERYOPTIONID
            ) ?? new ShippingDeliveryMethod();
          return;
      }
    }
  }
  /* Loaders */
  async getDeliveryOptions() {
    try {
      const { data: deliveryOptions } = await ShippingService.GetDeliveryOptions(
        this.isInternational
      );
      this.deliveryOptions = deliveryOptions.map(
        (d: any) => (d = new ShippingDeliveryMethod(d))
      );
      this.selectedDeliveryOption = this.orderline.ShippingDeliveryMethod;
    } catch (e) {
      console.log(e);
    }
  }
  async LoadRushFeeValues() {
    if (this.orderline) {
      const response = await OrdersService.GetRushFeeValues(
        ConversionHelper.convertOrderlineToAddModel(this.orderline)
      );
      this.rushOptions = response.data.filter((d: any) => d.IsAvailable);
    }
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
