
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { OrdersService } from '@/services/orders-service';
import { Email } from '@/store/models/CustomerModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class OrderConfirmationDialog extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  emailAddresses: Email[] = [];
  orderId: string = '';
  selectedEmailAddress: string = '';
  /* Computed */
  /* Async Functions */
  async sendOrderConfirmation() {
    this.isLoading = true;
    try {
      await OrdersService.SendOrderConfirmation(this.orderId, this.selectedEmailAddress);
      SetSnackBar('Sent Order Confirmation Successfully');
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isLoading = false;
  }
  /* Utility Functions */
  openDialog(orderId: string) {
    this.orderId = orderId;
    this.loadEmails();
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  async loadEmails() {
    this.isLoading = true;
    try {
      this.emailAddresses = [];
      const { data } = await CustomerService.GetEmailList();
      this.emailAddresses = data.map(d => new Email(d));
    } catch (err) {
      SetSnackBar('Could not load email addresses');
    }
    this.isLoading = false;
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
