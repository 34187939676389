
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { OrdersService } from '../services/orders-service';
import J1LInfoDialog from '@/components/J1LInfoDialog.vue';
import OrderEditQuantity from '@/components/OrderEditQuantity.vue';
import OrderEditShippingAddress from '@/components/OrderEditShippingAddress.vue';
import OrderEditRush from '@/components/OrderEditRush.vue';
import OrderEditDelete from '@/components/OrderEditDelete.vue';
import OrderEditRollSpecifications from './OrderEditRollSpecifications.vue';
import OrderIndividualReorder from '@/components/OrderlineReorder.vue';
import OrderPacklistDialog from '@/components/OrderPacklistDialog.vue';
import OrderEditRushAdmin from './OrderEditRushAdmin.vue';
import OrderConfirmationDialog from '@/components/OrderConfirmationDialog.vue';
import OrderNoteManagement from '@/components/OrderNoteManagement.vue';
import OrderZeroOutDialog from '@/components/OrderZeroOutDialog.vue';
import ItemOrderDialog from './ItemOrderDialog.vue';
import {
  Orderline,
  OrderlineDetails,
  OrderLineTypes,
  RushOption
} from '../store/models/OrderlineModel';
import { Address } from '../store/models/AddressModel';
import DieService from '@/services/die-service';
import { Item } from '@/store/models/ItemModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import {
  ConstValues,
  PrintStatusValues,
  RequestedOrderlineMetricTypes
} from '@/ConstValues';
import { Confirm } from '@/helpers/AuthHelper';

@Component({
  components: {
    OrderEditDelete,
    OrderEditQuantity,
    OrderEditShippingAddress,
    OrderEditRush,
    J1LInfoDialog,
    OrderIndividualReorder,
    ItemOrderDialog,
    OrderPacklistDialog,
    OrderEditRushAdmin,
    OrderEditRollSpecifications,
    OrderConfirmationDialog,
    OrderNoteManagement,
    OrderZeroOutDialog
  }
})
export default class extends Vue {
  $refs!: {
    EditQuantity: HTMLFormElement;
    OrderIndividualReorder: HTMLFormElement;
    OrderEditDelete: HTMLFormElement;
    OrderEditShippingAddress: HTMLFormElement;
    OrderEditRush: HTMLFormElement;
    J1LInfoDialog: HTMLFormElement;
    OrderEditRushAdmin: HTMLFormElement;
    OrderEditRollSpecifications: HTMLFormElement;
    OrderConfirmationDialog: HTMLFormElement;
    ItemOrderDialog: HTMLFormElement;
    OrderNoteManagement: HTMLFormElement;
    OrderZeroOutDialog: HTMLFormElement;
  };
  @Prop({ required: true })
  orderId!: string;
  @Prop({ required: true }) po!: string;
  @Prop() shouldUseInOrderActions!: boolean;
  @Prop() quantity!: number;
  @Prop() orderline!: Orderline | OrderlineDetails;
  @Prop() orderlinesOnOrder!: Array<Orderline | OrderlineDetails>;
  @Prop() isForDisplay!: boolean;
  @Getter('getUserValidation', { namespace: 'profile' })
  userValidation!: any;
  @Action('RefreshCustomerOrder', { namespace: 'orderlines' })
  refreshCustomerOrder: any;
  isGettingArtwork: boolean = false;
  isDesktop: boolean = false;
  drawer: boolean = false;
  j1lInfoDialogText: string = '';
  j1lInfoDialogTitle: string = '';

  // { action: 'Reorder', icon: 'mdi-cart-plus', color: '#2196F3' },
  actionItems: Array<{
    action: string;
    icon: string;
    color: string;
    textcolor: string;
    disabled: boolean;
  }> = [
    {
      action: 'Change Ship Date',
      icon: 'mdi-share-variant',
      color: this.hasOrderlineBeenDelivered() ? 'gray' : '#FF0E5B',
      textcolor: this.hasOrderlineBeenDelivered() ? 'gray' : '#000000',
      disabled: this.hasOrderlineBeenDelivered()
    },
    {
      action: 'Update Quantity',
      icon: 'mdi-pencil',
      color: this.isNotEditableAndDelivered ? 'gray' : '#FF0E5B',
      textcolor: this.isNotEditableAndDelivered ? 'gray' : '#000000',
      disabled: this.isNotEditableAndDelivered
    },
    {
      action: 'Change Address',
      icon: 'mdi-donkey',
      color: this.hasOrderlineBeenDelivered() ? 'gray' : '#FF0E5B',
      textcolor: this.hasOrderlineBeenDelivered() ? 'gray' : '#000000',
      disabled: this.hasOrderlineBeenDelivered()
    },
    {
      action: 'Certificate of Conformance',
      icon: 'mdi-donkey',
      color: !this.hasOrderlineBeenDelivered() ? 'gray' : '#FF0E5B',
      textcolor: !this.hasOrderlineBeenDelivered() ? 'gray' : '#000000',
      disabled: !this.hasOrderlineBeenDelivered()
    },
    {
      action: 'Edit Roll Specifications',
      icon: 'mdi-folder-edit',
      color: this.isNotEditable ? 'gray' : '#FF0E5B',
      textcolor: this.isNotEditable ? 'gray' : '#000000',
      disabled: this.isNotEditableAndDelivered
    },
    {
      action: 'Cancel this order line',
      icon: 'mdi-delete-forever',
      color: this.isNotEditable ? 'gray' : '#FF0E5B',
      textcolor: this.isNotEditable ? 'gray' : '#000000',
      disabled: !this.canEditProductionData() || this.hasOrderlineBeenDelivered()
    }
  ];
  downloadOptions: string[] = ['Download As PDF', 'Download Original Artwork'];

  handleEvent(i: string) {
    switch (i) {
      case 'Change Address':
        this.$refs.OrderEditShippingAddress.showDialog();
        return;
      case 'Update Quantity':
        if (this.canEditProductionData()) {
          this.$refs.EditQuantity.showDialog();
        }
        return;
      case 'Change Ship Date':
        this.$refs.OrderEditRush.showDialog();
        return;
      case 'Certificate of Conformance':
        Confirm(
          async () => {
            await OrdersService.GenerateCertificateOfConformance(
              this.orderline.Id,
              this.orderline.Item.PartNumber
            );
          },
          'Download Certificate of Conformance',
          'Are you sure you would like to download the Certificate of Conformance for this item?',
          'Cancel',
          'Download'
        );
        return;
      case 'Download Template':
        this.downloadDieTemplate();
        return;
      case 'Cancel this order line':
        if (this.canEditProductionData()) {
          this.$refs.OrderEditDelete.showDialog();
        }
        return;
      case 'Change Ship Date Admin': {
        this.$refs.OrderEditRushAdmin.showDialog();
        return;
      }
      case 'Edit Roll Specifications': {
        this.$refs.OrderEditRollSpecifications.openDialog(this.orderline);
        return;
      }
      case 'Send Order Confirmation': {
        this.$refs.OrderConfirmationDialog.openDialog(this.orderline.CustomerOrderId);
        return;
      }
      case 'Zero out orderline': {
        this.$refs.OrderZeroOutDialog.openDialog(this.orderlinesOnOrder);
        return;
      }
      case 'Manage Notes': {
        this.$refs.OrderNoteManagement.openDialog(this.orderline.Id);
        return;
      }
    }
  }
  refreshItem(item: Item) {
    this.$emit('refreshItem', item);
  }
  async downloadDieTemplate() {
    SetSnackBar('Downloading Template...');
    let dieTemplate = this.$store.getters['dies/getDieById'](this.orderline.Die.Id);
    DieService.DownloadDieTemplates(dieTemplate);
  }
  changeAddress(shippingAddress: Address) {
    if (this.shouldUseInOrderActions) {
      let orderlineToChange = Object.assign({}, this.orderline);
      orderlineToChange.Address = shippingAddress;
      (orderlineToChange as OrderlineDetails).ShippingAddress = shippingAddress;
      orderlineToChange.IsCustomerPickUp = shippingAddress.IsCustomerPickup;
      if (!shippingAddress.IsCustomerPickup)
        orderlineToChange.RollDirection.Id = shippingAddress.Options.RollDirectionId;
      if (shippingAddress.ShouldChangeAllAddresses) {
        this.$emit('addressChanged', shippingAddress);
      }
      this.$emit('updateOrderline', orderlineToChange);
    } else {
      this.$emit('addressChanged', {
        orderId: this.orderId,
        shippingAddress
      });
    }
  }
  async downloadPacklist() {
    try {
      if (this.orderline.IsShippedOrGreater) {
        const response = await OrdersService.GetPacklistByOrderlineId(this.orderline.Id);
        let plnumber = response.data.PlNumber;
        OrdersService.DownloadPacklist(plnumber);
      }
    } catch (err) {
      console.log(err);
    }
  }
  reorder() {
    this.$refs.ItemOrderDialog.openDialog(this.orderline);
  }
  selectRush(rush: any) {
    if (this.shouldUseInOrderActions) {
      const updateOrderline = (orderlineId: string) => {
        console.log(this.orderlinesOnOrder.find(ol => ol.Id == orderlineId));
        let orderlineToChange = Object.assign(
          {},
          this.orderlinesOnOrder.find(ol => ol.Id == orderlineId)
        );
        orderlineToChange.RushOption = rush.rush;
        orderlineToChange.DueDate = rush.rush.DueDate;
        orderlineToChange.IsARush = rush.rush.DayValue < 10;
        orderlineToChange.ShippingDeliveryMethod.Id = rush.deliveryOption;
        this.$emit('updateOrderline', orderlineToChange);
      };
      for (let ol of [this.orderline.Id, rush.additionalIdsToRush].flat()) {
        updateOrderline(ol);
      }
    } else {
      this.$emit('rushSelected', { orderId: this.orderId, ...rush });
    }
  }
  async changeRollSpecifications(orderlineToChange: OrderlineDetails) {
    if (this.shouldUseInOrderActions) {
      this.$emit('updateOrderline', orderlineToChange);
    } else {
      try {
        var model = {
          Id: this.orderline.Id,
          RequestedOrderlineMetricType: orderlineToChange.RequestedOrderlineMetricType,
          RequestedRollQuantity: orderlineToChange.RequestedRollQuantity,
          RequestedMaxLabelQuantity: orderlineToChange.RequestedMaxLabelQuantity,
          PO: this.orderline.PO
        };
        await OrdersService.ChangeRollSpecifications(model);
        SetSnackBar('Changed roll specifications successfully');
        this.$emit('refreshOrderline');
      } catch (err) {
        SetSnackBar(
          'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
        );
      }
    }
  }
  deleteOrderline(response: boolean) {
    this.$emit('deleteOrderline', {
      Id: this.orderline.Id,
      AmazonRequest: response
    });
  }
  canEditProductionData() {
    return this.orderline.CanEditProductionData;
  }
  hasOrderlineBeenDelivered() {
    return this.orderline.CanBeReordered || !!this.orderline.DatePackaged;
  }
  canOrderlineBeRushed() {
    return this.orderline.Item.IsApproved;
  }
  get isNotEditableAndDelivered() {
    return (
      !this.canEditProductionData() ||
      this.hasOrderlineBeenDelivered() ||
      !this.canOrderlineTypeSupportAction()
    );
  }
  get isNotEditable() {
    return !this.canEditProductionData() || this.hasOrderlineBeenDelivered();
  }
  get ConstValues() {
    return ConstValues;
  }
  canOrderlineTypeSupportAction() {
    switch (this.orderline.OrderLineType) {
      case OrderLineTypes.Proof: {
        return true;
      }
      case OrderLineTypes.Print: {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  removeRush() {
    let rush = new RushOption({ Id: '' });
    this.$emit('rushSelected', { orderId: this.orderId, rush });
  }

  async download(option: string) {
    this.isGettingArtwork = true;
    switch (option) {
      case 'Download As PDF':
        // await ItemService.getOriginalArtworkAsPdf(this.itemId, this.partNumber);
        this.isGettingArtwork = false;
        return;
      case 'Download Original Artwork':
        // await ItemService.getOriginalArtwork(this.itemId, this.partNumber);
        this.isGettingArtwork = false;
        return;
    }
  }

  created() {
    this.isDesktop = !this.isMobile();
    this.$watch(
      () => {
        return this.$vuetify.breakpoint.width;
      },
      val => {
        this.isDesktop = !this.isMobile();
      }
    );
  }
  isMobile() {
    return this.$vuetify.breakpoint.width <= 1200;
  }
  update(value: boolean) {
    this.drawer = value;
  }
  changeQuantity(quantity: number) {
    if (this.shouldUseInOrderActions) {
      let orderlineToChange = Object.assign({}, this.orderline);
      orderlineToChange.Quantity = quantity;
      orderlineToChange.IsHandApply = false;
      orderlineToChange.RequestedOrderlineMetricType = RequestedOrderlineMetricTypes.None;
      this.$emit('updateOrderline', orderlineToChange);
    } else {
      this.$emit('changeQuantity', {
        orderId: this.orderId,
        quantity: quantity
      });
    }
  }
  updateOrderline(orderline: OrderlineDetails) {
    this.$emit('updateOrderline', orderline);
  }
  mounted() {
    if (this.userValidation.isAdmin) {
      this.actionItems.push({
        action: 'Change Ship Date Admin',
        icon: 'mdi-share-variant',
        color:
          this.orderline.PrintStatusValue > PrintStatusValues.ReadyToInvoice
            ? 'gray'
            : '#FF0E5B',
        textcolor:
          this.orderline.PrintStatusValue > PrintStatusValues.ReadyToInvoice
            ? 'gray'
            : '#000000',
        disabled: this.orderline.PrintStatusValue > PrintStatusValues.ReadyToInvoice
      });
      this.actionItems.push({
        action: 'Send Order Confirmation',
        icon: 'mdi-email',
        color: '#FF0E5B',
        textcolor: '#000000',
        disabled: false
      });
      this.actionItems.push({
        action: 'Zero out orderline',
        icon: 'mdi-numeric-0-box',
        color: '#FF0E5B',
        textcolor: '#000000',
        disabled: false
      });
      this.actionItems.push({
        action: 'Manage Notes',
        icon: 'mdi-note',
        color: '#FF0E5B',
        textcolor: '#000000',
        disabled: false
      });
    }
  }
}
