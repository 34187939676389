
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DialogLayout from '@/components/DialogLayout.vue';
import RushIcon from '@/components/Icons/RushIcon.vue';
import {
  Orderline,
  OrderlineDetails,
  RushOption,
  ShippingDeliveryMethod
} from '../store/models/OrderlineModel';
import { OrdersService } from '../services/orders-service';
import { ShippingService } from '@/services/shipping-service';
import { ConversionHelper } from '../helpers/ConversionHelper';
import { AxiosResponse } from 'axios';
import { QuickDialog } from '../store/models/DialogModel';
import ItemService from '@/services/item-service';
import { Item } from '@/store/models/ItemModel';
import { ConstValues } from '../ConstValues';
import { IsLikeItem } from '@/helpers/OrderlineHelper';
@Component({
  components: { DialogLayout, RushIcon }
})
export default class OrderEditRush extends Vue {
  $refs!: {};
  /* Properties */
  @Prop({ required: true })
  orderline!: OrderlineDetails;
  @Prop({
    default: () => {
      return [];
    }
  })
  orderlinesOnOrder!: Array<OrderlineDetails>;
  @Prop()
  isInternational!: boolean;
  /* Store Actions */
  @Action('refreshItem', { namespace: 'items' })
  refreshItem: any;
  @Getter('getItemByPublicId', { namespace: 'items' })
  itemById: any;
  @Getter('getDeliveryMethodFromRushDay', { namespace: 'orderlines' })
  getDeliveryMethodFromRushDay: (
    dayValue: number,
    isInternational: boolean
  ) => ShippingDeliveryMethod;
  @Getter('shippingDeliveryOptions', { namespace: 'orderlines' })
  getDeliveryOptionsFromStore: (isInternational: boolean) => ShippingDeliveryMethod[];
  /* Watchers */
  /* Data */
  dialog: boolean = false;
  deliveryOptions: ShippingDeliveryMethod[] = [];
  selectedDeliveryOption: ShippingDeliveryMethod = new ShippingDeliveryMethod();
  rushOptions: RushOption[] = [new RushOption()];
  rushOption: RushOption = new RushOption();
  isGettingRushFees: boolean = false;
  dialogLayoutData: QuickDialog = new QuickDialog();
  shouldShowItemApprovedAlert: boolean = false;
  originalRushOption: RushOption = new RushOption();
  originalDeliveryOption: ShippingDeliveryMethod = new ShippingDeliveryMethod();
  hasChanged: boolean = false;
  gangRushType: { id: number; desc: string } = { id: 0, desc: 'No, rush this item only' };
  gangRushTypes: { id: number; desc: string }[] = [
    { id: 0, desc: 'No, rush this item only' },
    { id: 1, desc: 'Yes, rush items that match' },
    { id: 2, desc: 'Yes, Rush my entire order' }
  ];
  /* Methods */
  async showDialog() {
    this.dialog = true;
    if (this.orderline) {
      this.dialogLayoutData = ConversionHelper.convertOrderlineToDialogLayout(
        this.orderline
      );
    }
    this.getDeliveryOptions();
    await this.LoadRushFeeValues();
    if (
      this.orderline.RushOption.DayValue > 0 &&
      this.orderline.RushOption.DayValue < 10
    ) {
      this.rushOption = this.rushOptions.find(
        r => r.DayValue === this.orderline.RushOption.DayValue
      )!;
    } else {
      this.rushOption = this.rushOptions[0];
    }
    this.originalRushOption = this.rushOption;
  }
  closeDialog() {
    this.dialog = false;
  }
  canChangeShipDate() {
    return (
      this.orderline.Die.IsInStock &&
      !this.orderline.Die.IsOnOrder &&
      this.orderline.Item.IsApproved &&
      (this.rushOption !== this.originalRushOption ||
        this.selectedDeliveryOption.Id !== this.originalDeliveryOption.Id)
    );
  }
  goToApproveItem() {
    let newWindow = window.open('', '_blank', 'height=1440,width=1440');
    setTimeout(() => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari)
        newWindow!.location.href = `${process.env.VUE_APP_BASE_URL}/approveItem/${this.orderline.Item.Id}/true`;
      else newWindow!.location.href = `/approveItem/${this.orderline.Item.Id}/true`;
      newWindow!.focus();
    }, 250);
    window.onfocus = async () => {
      const response = await ItemService.RefreshItem(this.orderline.Item.Id);
      if (response.data.IsApproved) {
        this.orderline.Item = new Item(response.data);
        this.LoadRushFeeValues();
        this.$emit('refreshItem', this.orderline.Item);
        this.refreshItem(this.orderline.Item.Id);
        this.dialogLayoutData = ConversionHelper.convertOrderlineToDialogLayout(
          this.orderline
        );
        this.shouldShowItemApprovedAlert = true;
      }
    };
  }
  setRushOption(option: any) {
    this.rushOption = option;
  }
  getRushNameByDayValue(dayValue: number) {
    return ConversionHelper.getRushNameByDayValue(dayValue);
  }
  getRushTextByDayValue(dayValue: number) {
    return ConversionHelper.getRushTextByDayValue(dayValue);
  }
  updateDeliveryOptionFromRushDate(dayValue: number) {
    this.selectedDeliveryOption = this.getDeliveryMethodFromRushDay(
      dayValue,
      this.isInternational
    );
  }
  async getDeliveryOptions() {
    try {
      this.deliveryOptions = this.getDeliveryOptionsFromStore(this.isInternational);
      this.selectedDeliveryOption = this.orderline.ShippingDeliveryMethod;
    } catch (e) {
      console.log(e);
    }
  }

  applyRush() {
    this.$emit('rushSelected', {
      rush: this.rushOption,
      deliveryOption: this.selectedDeliveryOption.Id,
      additionalIdsToRush: this.getGangTypeOrderlines().map(ol => ol.Id)
    });
    this.closeDialog();
  }
  getGangTypeOrderlines() {
    switch (this.gangRushType.id) {
      case 0:
        return [];
      case 1:
        return this.likeItemOrderlines;
      case 2:
        return this.allOrderlines;
    }
  }

  calculateDifference() {
    switch (this.gangRushType.id) {
      case 0:
        return this.orderline.RushFee - this.rushOption.RushFee;
      case 1:
        return (
          this.likeItemOrderlines.reduce((sum, ol) => {
            return sum + ol.RushFee;
          }, 0) -
          (this.rushOption.RushFee +
            (this.likeItemOrderlines.length - 1) * this.rushOption.RushFee * 0.1)
        );
      case 2: {
        const currentAmount = this.allOrderlines.reduce((sum, ol) => {
          return sum + ol.RushFee;
        }, 0);
        let newAmount =
          this.rushOption.RushFee +
          (this.likeItemOrderlines.length - 1) * this.rushOption.RushFee * 0.1;
        let uniqueOlList = [];
        for (const ol of this.allOrderlines) {
          if (this.likeItemOrderlines.find(o => o.Id == ol.Id)) continue;
          if (!uniqueOlList.some(o => IsLikeItem(o, ol))) {
            newAmount += this.rushOption.RushFee;
            uniqueOlList.push(ol);
          } else {
            newAmount += this.rushOption.RushFee * 0.1;
          }
        }
        return currentAmount - newAmount;
      }
    }
  }

  /* Loaders */
  async LoadRushFeeValues() {
    if (this.orderline) {
      const response = await OrdersService.GetRushFeeValues(
        ConversionHelper.convertOrderlineToAddModel(this.orderline)
      );
      this.rushOptions = response.data.filter(
        (d: any) => d.IsAvailable || d.DayValue === this.orderline.RushOption?.DayValue
      );
    }
  }
  get ConstValues() {
    return ConstValues;
  }
  get likeItemOrderlines() {
    return this.allOrderlines.filter(ol => IsLikeItem(this.orderline, ol));
  }
  get allOrderlines() {
    return this.orderlinesOnOrder.filter(ol => ol.Item.IsApproved && !ol.IsARetention);
  }
  /* Mounted */
  mounted() {}
  /* Created */
}
