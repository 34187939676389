
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { OrdersService } from '../services/orders-service';
import { DateHelper } from '@/helpers/DateHelper';
@Component
export default class extends Vue {
  /* Properties */
  /* Store Actions */
  @Prop()
  initialPo!: string;
  @Prop()
  shouldNavigate!: boolean;
  @Prop()
  isReadonly!: boolean;
  /* Watchers */
  @Watch('po')
  async onPOChanged() {
    await this.checkForDuplicates();
    this.poChanged();
  }
  /* Data */
  dialog: boolean = false;
  shouldShowDuplicateAlert: boolean = false;
  po: string = '';
  isPOValid: boolean = false;
  isCheckingForDuplicates = false;
  debounceAction: any;
  /* Methods */
  poChanged() {
    this.debounceAction = DateHelper.Debounce(
      () => {
        if (
          !this.isCheckingForDuplicates &&
          !this.shouldShowDuplicateAlert &&
          this.isPOValid
        ) {
          this.$emit('PoChanged', this.po);
        }
      },
      this.debounceAction,
      1500
    );
  }
  goToDuplicateOrder() {
    this.$emit('GoToDuplicateOrder', this.po);
  }
  async checkForDuplicates() {
    if (!this.po) return;
    this.isCheckingForDuplicates = true;
    setTimeout(async () => {
      if (this.isPOValid && this.po !== this.initialPo) {
        const response = await OrdersService.GetOrderByPO(this.po);
        if (response.data) this.shouldShowDuplicateAlert = true;
        else {
          this.shouldShowDuplicateAlert = false;
        }
        this.isCheckingForDuplicates = false;
      }
      this.isCheckingForDuplicates = false;
    }, 100);
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    if (this.initialPo) this.po = this.initialPo;
  }
  /* Created */
}
