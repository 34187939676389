
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DialogLayout from '@/components/DialogLayout.vue';
import { CustomerService } from '@/services/customer-service';
import { Orderline } from '@/store/models/OrderlineModel';
import { QuickDialog } from '@/store/models/DialogModel';
import { ConversionHelper } from '../helpers/ConversionHelper';
import RollDirectionAlert from '@/components/RollDirectonAlert.vue';
import { Address } from '@/store/models/AddressModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';

@Component({
  components: { DialogLayout, RollDirectionAlert }
})
export default class extends Vue {
  /* Properties */
  /* Store Actions */
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  @Getter('getCustomer', { namespace: 'customer' })
  currentCustomer: any;
  @Prop()
  shippingAddresses!: any;
  @Prop()
  selectedShippingAddress!: any;
  @Prop()
  orderline!: Orderline;
  /* Watchers */

  /* Data */
  dialog: boolean = false;
  shouldApplyToEntireOrder: boolean = false;
  shippingAddress: any = null;
  addressOptions: any[] = [];
  dialogLayoutData: QuickDialog = new QuickDialog();
  defaultCustomerRollDirection!: number;
  rollDirectionOptions: any = [
    {
      title: '#4 unwind',
      description: 'Left side of label dispenses first. Wound Outside',
      src: require('@/assets/4unwind.svg'),
      value: 4
    },
    {
      title: '#3 unwind',
      description: 'Right side of label dispenses first. Wound Outside',
      src: require('@/assets/3unwind.svg'),
      value: 3
    }
  ];
  selectedRollDirection: number | null = null;
  shouldShowAlert: boolean = false;
  isUpdatingRollDirection: boolean = false;
  /* Methods */
  test(input: any) {
    console.log(input);
  }
  async showDialog() {
    await this.getCurrentCustomer();
    if (!this.shippingAddresses || !this.shippingAddresses[0]) {
      await this.getShippingAddresses();
    } else {
      this.addressOptions = this.shippingAddresses;
    }
    if (this.selectedShippingAddress) {
      this.shippingAddress = this.addressOptions.find(
        a => a.Id === this.selectedShippingAddress
      );
      if (this.shippingAddress) {
        this.selectedRollDirection = this.shippingAddress.Options.RollDirectionId;
        this.shouldShowAlert = this.selectedRollDirection === 10;
      } else {
        this.addressOptions.unshift(this.orderline.Address);
        this.shippingAddress = this.addressOptions[0];
      }
    }
    if (this.orderline) {
      this.dialogLayoutData = ConversionHelper.convertOrderlineToDialogLayout(
        this.orderline
      );
    }
    this.setSelectedRollDirection();
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }

  addressChangeClick() {
    this.shippingAddress.ShouldChangeAllAddresses = this.shouldApplyToEntireOrder;
    this.$emit('addressChanged', this.shippingAddress);
    this.shouldApplyToEntireOrder = false;
    this.closeDialog();
  }
  /* Loaders */
  async getCurrentCustomer() {
    if (!this.currentCustomer.Name) await this.getCurrentCustomerStore();
    let currentCustomer = this.currentCustomer;
    this.defaultCustomerRollDirection = currentCustomer.Options.RollDirection.Position;
  }
  setSelectedRollDirection() {
    this.selectedRollDirection = this.shippingAddress.IsCustomerPickup
      ? this.defaultCustomerRollDirection
      : this.shippingAddress.Options.RollDirectionId;
    this.shouldShowAlert = this.selectedRollDirection === 10;
  }
  onAddressChange() {
    this.shouldShowAlert = false;
    this.setSelectedRollDirection();
    this.shouldShowAlert = this.selectedRollDirection === 10;
  }
  onUpdateRollDirectionId(rollDirectionId: number) {
    this.selectedRollDirection = rollDirectionId;
  }
  updateRollDirection() {}

  closeAlert() {
    this.shouldShowAlert = false;
  }
  async getShippingAddresses() {
    try {
      const response = await CustomerService.GetShippingAddressesFull();
      this.addressOptions = response.data.map((r: any) => (r = new Address(r)));
    } catch (err) {
      console.log(err);
      SetSnackBar(
        `There was an error grabbing your shipping address. 
        Please refresh your page, or contact techincal support if error persists`
      );
    }
  }
  /* Mounted */
  async mounted() {}
  /* Created */
}
