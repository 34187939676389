
export class HandApplyOption {
  Quantity: number = 0;
  IsAvailable: boolean = false;
  UnavailableReason: string = '';
  constructor(rawItem: any) {
    this.Quantity = rawItem.Item1;
    this.IsAvailable = rawItem.Item2;
    this.UnavailableReason = rawItem.Item3;
  }
}

import { RequestedOrderlineMetricTypes } from '@/ConstValues';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { OrdersService } from '@/services/orders-service';
import { OrderlineDetails } from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class OrderEditRollSpecifications extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  handApplyOptions: HandApplyOption[] = [];
  rollQuantityOptions: string[] = ['Off'];
  selectedRollQuantity: number | string = 'Off';
  orderline: OrderlineDetails = new OrderlineDetails();
  rollSpecificationOptions: any[] = [
    {
      action: 'mdi-silverware-fork-knife',
      active: true,
      items: [{ title: 'The more rolls the better' }],
      title: 'Hand applied - maximum rolls',
      subtitle: 'The more rolls the better.',
      OrderlineMetricType: RequestedOrderlineMetricTypes.RollQuantity
    }
  ];
  /* Computed */
  get selectedRollQuantityFormatted(): number | string {
    return this.rollQuantityOptions[this.selectedRollQuantity];
  }
  /* Async Functions */
  /* Utility Functions */
  get isValid() {
    return (
      this.selectedRollQuantity == 0 ||
      this.handApplyOptions[(this.selectedRollQuantity as number) - 1]?.IsAvailable
    );
  }
  resetDefaultValues() {
    this.rollQuantityOptions = ['Off'];
    this.selectedRollQuantity = 'Off';
    this.orderline = new OrderlineDetails();
  }
  async openDialog(orderline: OrderlineDetails) {
    this.resetDefaultValues();
    this.orderline = orderline;
    if (!this.orderline.RequestedRollQuantity) {
      const { data } = await OrdersService.RefreshOrderline(orderline.Id);
      this.orderline = new OrderlineDetails(data);
    }
    await this.GetAvailableRollQuantities();
    if (this.orderline.IsHandApply)
      this.selectedRollQuantity = this.rollQuantityOptions.findIndex(
        r => parseInt(r) === this.orderline.RequestedRollQuantity
      );
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  buildRollQuantityOptions() {
    this.rollQuantityOptions.push(
      ...this.handApplyOptions.map(h => h.Quantity.toString())
    );
  }
  canChangeRollQuantity() {
    return this.orderline.Die.NumberAcross * 7 <= this.orderline.Quantity;
  }
  canSubmit() {
    return (
      this.selectedRollQuantity == 0 ||
      this.handApplyOptions[(this.selectedRollQuantity as number) - 1]?.IsAvailable
    );
  }
  changeRollSpecifications() {
    this.setValues();
    this.$nextTick(() => {
      this.$emit('changeRollSpecifications', this.orderline);
    });
    this.closeDialog();
  }
  setValues() {
    switch (this.rollSpecificationOptions.find(r => r.active).OrderlineMetricType) {
      case RequestedOrderlineMetricTypes.RollQuantity: {
        const rollQuantity = parseInt(this.selectedRollQuantityFormatted as string);
        this.orderline.RequestedOrderlineMetricType =
          RequestedOrderlineMetricTypes.RollQuantity;
        if (this.selectedRollQuantityFormatted == 'Off') {
          this.orderline.RequestedRollQuantity = this.orderline.GeneratedRollQuantity;
        } else {
          this.orderline.RequestedMaxLabelQuantity = Math.ceil(
            this.orderline.Quantity / rollQuantity
          );
          this.orderline.RequestedRollQuantity = rollQuantity;
        }
        return;
      }
    }
  }
  /* Loaders */
  async GetAvailableRollQuantities() {
    try {
      let model = {
        OrderlineToEdit: ConversionHelper.convertOrderlineToAddModel(this.orderline),
        RequestedValueToChange: RequestedOrderlineMetricTypes.RollQuantity
      };
      const { data } = await OrdersService.CalculateExpectedRollSpecifications(model);
      this.handApplyOptions = data.AvailableRollQuantities.map(
        d => new HandApplyOption(d)
      );
      this.buildRollQuantityOptions();
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
