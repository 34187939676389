
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DialogLayout from '@/components/DialogLayout.vue';
import { Orderline, OrderLineTypes, OrderStatus } from '../store/models/OrderlineModel';
import { QuickDialog } from '../store/models/DialogModel';
import { ConversionHelper } from '../helpers/ConversionHelper';
import { OrdersService } from '@/services/orders-service';
import { StringHelper } from '@/helpers/StringHelper';
@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  $refs!: {
    QuantityTextField: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Prop()
  orderline!: Orderline;
  @Prop({ default: true })
  shouldCommitImmediately!: boolean;
  /* Watchers */
  @Watch('dialog')
  onDialogChange() {
    if (this.dialog) {
      setTimeout(() => {
        if (this.orderline.OrderLineType !== OrderLineTypes.Proof)
          this.$refs.QuantityTextField.focus();
      });
    }
  }
  @Watch('quantity')
  onQuantityChange() {
    if (!this.shouldCommitImmediately) return;
    if (this.quantity != this.orderline.Quantity) {
      this.getQuote();
      this.shouldUpdate = true;
    } else {
      this.difference = 0;
      this.shouldUpdate = false;
    }
  }

  /* Data */
  dialog: boolean = false;
  confirmationDialog: boolean = false;
  quantity: number = 0;
  quantitiesOption: number[] = [1, 2, 3, 4, 5];
  dialogLayoutData: QuickDialog = new QuickDialog();
  increaseOrDecrease: string = 'Increase';
  difference: number = 0;
  currentPrice: number = 0;
  shouldUpdate: boolean = false;
  isTextInputValid: boolean = false;
  /* Methods */
  async showDialog() {
    if (this.orderline) {
      this.quantity = this.orderline.Quantity;
      this.dialogLayoutData = ConversionHelper.convertOrderlineToDialogLayout(
        this.orderline
      );
      if (this.orderline.OrderLineType == OrderLineTypes.Proof) {
        const { data } = await OrdersService.CalculateProofPrice(this.quantity);
        this.currentPrice = data;
      }
    }

    this.dialog = true;
  }
  closeDialog() {
    this.confirmationDialog = false;
    this.dialog = false;
  }
  quantityChanged() {
    this.$emit('quantityChanged', this.quantity);
    this.closeDialog();
  }
  hasChanged() {
    return this.quantity !== this.orderline.Quantity;
  }
  updateOrderline() {
    if (this.shouldCommitImmediately) this.confirmationDialog = true;
    else this.quantityChanged();
  }
  async getQuote() {
    if (this.orderline.OrderLineType === OrderLineTypes.Proof) {
      const { data } = await OrdersService.CalculateProofPrice(this.quantity);
      this.difference = this.currentPrice - data;
      this.increaseOrDecrease = this.difference <= 0 ? 'increase' : 'decrease';
      return;
    }
    if (this.isTextInputValid) {
      let model = ConversionHelper.convertOrderlineToAddModel(this.orderline);
      model.QuotedQuantity = this.quantity;
      model.IsQuote = true;
      const response = await OrdersService.CalculatePricingData([model]);
      this.difference = Math.abs(response.data);
      this.increaseOrDecrease = response.data >= 0 ? 'increase' : 'decrease';
    }
  }
  handleQuantityInput(newValue: any) {
    this.quantity = parseInt(newValue.toString().replace(/\D/g, ''));
  }
  get formattedQuantity() {
    return StringHelper.formatQuantity(this.quantity);
  }
  get OrderLineTypes() {
    return OrderLineTypes;
  }
  /* Loaders */
  /* Mounted */
  mounted() {}
  /* Created */
}
